<template>

  <Carousel :items-to-show="2.5" class="no-padding">
      <slide v-for="(slide, i) in slides" :key="i" :title="slide.title" :src="slide.image" >
        <a :href="slide.src" class="glightbox">
          <img :src="slide.src" :alt="slide.content">
        </a>
      </slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </Carousel>
</template>


<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import GLightbox from "glightbox";
import  "glightbox/dist/css/glightbox.css";
import  "glightbox/dist/js/glightbox.js";
export default {
  data: () => ({
    slides: [
      {
        title: 'Search events',
        content: 'Search events',
        src: 'images/mockup/13.jpg'
      },
      {
        title: 'Find your slot',
        content: 'Find your slot',
        src: 'images/mockup/13.jpg'
      },
      {
        title: 'Slide #3',
        content: 'Slide content.',
        src: 'images/mockup/13.jpg'
      },
      {
        title: 'Slide #4',
        content: 'Slide content.',
        src: 'images/mockup/9.jpg'
      },
      {
        title: 'Slide #5',
        content: 'Slide content.',
        src: 'images/mockup/10.jpg'
      }
    ]
  }),
  name: 'Slider',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  mounted(){
    //lightbox settings
    this.lightbox = GLightbox({
      selector: ".glightbox"
    });

  }
}
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color:  var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 1px;
}
.carousel__slide a, .carousel__slide img{
  width: 100%;
}
.carousel__prev{
  margin-left:40px;
}
.carousel__next{
  margin-right:40px;
}
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>