<template>
  <footer id="footer">
    <div class="footer-content" v-if="false">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="widget">

              <div class="widget-title">Polo HTML5 Template</div>
              <p class="mb-5">Built with love in Fort Worth, Texas, USA<br> All rights reserved. Copyright © 2021. INSPIRO.</p>
              <a href="https://themeforest.net/item/polo-responsive-multipurpose-html5-template/13708923" class="btn btn-inverted" target="_blank">Purchase Now</a>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="row">
              <div class="col-lg-3">
                <div class="widget">
                  <div class="widget-title">Discover</div>
                  <ul class="list">
                    <li><a href="#">Features</a></li>
                    <li><a href="#">Layouts</a></li>
                    <li><a href="#">Corporate</a></li>
                    <li><a href="#">Updates</a></li>
                    <li><a href="#">Pricing</a></li>
                    <li><a href="#">Customers</a></li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="widget">
                  <div class="widget-title">Features</div>
                  <ul class="list">
                    <li><a href="#">Layouts</a></li>
                    <li><a href="#">Headers</a></li>
                    <li><a href="#">Widgets</a></li>
                    <li><a href="#">Footers</a></li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="widget">
                  <div class="widget-title">Pages</div>
                  <ul class="list">
                    <li><a href="#">Portfolio</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="#">Shop</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="widget">
                  <div class="widget-title">Support</div>
                  <ul class="list">
                    <li><a href="#">Help Desk</a></li>
                    <li><a href="#">Documentation</a></li>
                    <li><a href="#">Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div class="copyright-content">
      <div class="container">
        <div class="copyright-text text-center">&copy; 2022 VM - made by v.micaleATgmail.com. All Rights Reserved.<a href="https://dot-it.it" target="_blank" rel="noopener"> dotIT</a> </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Footer'
}
</script>