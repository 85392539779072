<template>
  <section class="background-grey text-dark">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">

          <div class="text-center">
            <div class="icon"><i class="fa fa-3x fa-code"></i></div>
            <div class="counter"> <span data-speed="3000" data-refresh-interval="50" data-to="12416" data-from="600" data-seperator="true"></span> </div>
            <div class="seperator seperator-small"></div>
            <p>LINES OF CODE</p>
          </div>
        </div>

        <div class="col-lg-3">

          <div class="text-center">
            <div class="icon"><i class="fa fa-3x fa-coffee"></i></div>
            <div class="counter"> <span data-speed="4500" data-refresh-interval="23" data-to="365" data-from="100" data-seperator="true"></span> </div>
            <div class="seperator seperator-small"></div>
            <p>CUPS OF COFFEE</p>
          </div>
        </div>

        <div class="col-lg-3">

          <div class="text-center">
            <div class="icon"><i class="fa fa-3x fa-rocket"></i></div>
            <div class="counter"> <span data-speed="3000" data-refresh-interval="12" data-to="114" data-from="50" data-seperator="true"></span> </div>
            <div class="seperator seperator-small"></div>
            <p>FINISHED PROJECTS</p>
          </div>
        </div>

        <div class="col-lg-3">

          <div class="text-center">
            <div class="icon"><i class="fa fa-3x fa-smile-o"></i></div>
            <div class="counter"> <span data-speed="4550" data-refresh-interval="50" data-to="14825" data-from="48" data-seperator="true"></span> </div>
            <div class="seperator seperator-small"></div>
            <p>SATISFIED CLIENTS</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'Counters'
}
</script>
