<template>
  <section>
    <div class="container">
      <div class="heading-text heading-section">
        <h2>{{ $t("SERVICES.TITLE") }}</h2>
        <span class="lead">{{ $t("SERVICES.SUB_TITLE") }}</span>
      </div>

      <div class="row">
        <div class="col-lg-4" data-animate="flipInY" data-animate-delay="0">
          <div class="icon-box effect small">
            <div class="icon">
              <a href="#"><i class="fa fa-shield-alt"></i></a>
            </div>
            <h3>{{ $t("SERVICES.SECURITY_TITLE") }}</h3>
            <p>{{ $t("SERVICES.SECURITY_TEXT") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4" data-animate="flipInY" data-animate-delay="200">
          <div class="icon-box effect small ">
            <div class="icon">
              <a href="#"><i class="fa fa-user-astronaut"></i></a>
            </div>
            <h3>{{ $t("SERVICES.SEMPLICITY_TITLE") }}</h3>
            <p>{{ $t("SERVICES.SEMPLICITY_TEXT") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4" data-animate="flipInY" data-animate-delay="400">
          <div class="icon-box effect small ">
            <div class="icon">
              <a href="#"><i class="fa fa-shipping-fast"></i></a>
            </div>
            <h3>{{ $t("SERVICES.FASTER_TITLE") }}</h3>
            <p>{{ $t("SERVICES.FASTER_TEXT") }}
            </p>
          </div>
        </div>

        <div class="col-lg-4" data-animate="flipInY" data-animate-delay="600">
          <div class="icon-box effect small ">
            <div class="icon">
              <a href="#"><i class="far fa-life-ring"></i></a>
            </div>
            <h3>{{ $t("SERVICES.CUSTOMER_CARE_TITLE") }}</h3>
            <p>{{ $t("SERVICES.CUSTOMER_CARE_TEXT") }}</p>
          </div>
        </div>
        <div class="col-lg-4" data-animate="flipInY" data-animate-delay="800">
          <div class="icon-box effect small ">
            <div class="icon">
              <a href="#"><i class="fa fa-chalkboard-teacher"></i></a>
            </div>
            <h3>{{ $t("SERVICES.TRAINING_TITLE") }}</h3>
            <p>{{ $t("SERVICES.TRAINING_TEXT") }}</p>
          </div>
        </div>
        <div class="col-lg-4" data-animate="flipInY" data-animate-delay="1000">
          <div class="icon-box effect small ">
            <div class="icon">
              <a href="#"><i class="fa fa-database"></i></a>
            </div>
            <h3>{{ $t("SERVICES.SAAS_TITLE") }}</h3>
            <p>{{ $t("SERVICES.SAAS_TEXT") }}</p>
          </div>
        </div>

        <div class="col-lg-4" data-animate="flipInY" data-animate-delay="1200" v-if="false">
          <div class="icon-box effect small ">
            <div class="icon">
              <a href="#"><i class="fa fa-rocket"></i></a>
            </div>
            <h3>Modern Design</h3>
            <p>Lorem ipsum dolor sit amet, consecte adipiscing elit. Suspendisse condimentum porttitor cursumus.
            </p>
          </div>
        </div>
        <div class="col-lg-4" data-animate="flipInY" data-animate-delay="1400" v-if="false">
          <div class="icon-box effect small ">
            <div class="icon">
              <a href="#"><i class="fa fa-flask"></i></a>
            </div>
            <h3>Clean Modern Code</h3>
            <p>Lorem ipsum dolor sit amet, consecte adipiscing elit. Suspendisse condimentum porttitor cursumus.
            </p>
          </div>
        </div>
        <div class="col-lg-4" data-animate="flipInY" data-animate-delay="1600" v-if="false">
          <div class="icon-box effect small ">
            <div class="icon">
              <a href="#"><i class="fa fa-umbrella"></i></a>
            </div>
            <h3>Free Updates & Support</h3>
            <p>Lorem ipsum dolor sit amet, consecte adipiscing elit. Suspendisse condimentum porttitor cursumus.
            </p>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'Header'
}
</script>
