<template>

  <header id="header">
    <div class="header-inner">
      <div class="container">
        <!--Logo-->
        <div id="logo"><a href="/"><span class="logo-default">ReservationCare</span><span
            class="logo-dark">ReservationCore</span></a>
        </div>
        <!--End: Logo-->
        <!--Header Extras-->
        <div class="header-extras">
          <ul>
            <li>
              <div class="p-dropdown"><a href="#"><i class="icon-globe"></i><span>IT</span></a>
                <ul class="p-dropdown-content">
                  <li><a href="#">Italiano</a></li>
                  <li><a href="#">English</a></li>
                  <li><a href="#">Español</a></li>
                  <li><a href="#">Français</a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <!--end: Header Extras-->
        <!--Navigation Resposnive Trigger-->
        <div id="mainMenu-trigger"><a class="lines-button x"><span class="lines"></span></a></div>
        <!--end: Navigation Resposnive Trigger-->
        <!--Navigation-->
        <div id="mainMenu">
          <div class="container">
            <nav>
              <ul>
                <li><a href="/">{{ $t("MENU.HOME") }}</a></li>
                <li><a href="/">{{ $t("MENU.ABOUT_US") }}</a></li>
                <li><a href="/">{{ $t("MENU.HOW_IT_WORK") }}</a></li>
                <li><a href="/">{{ $t("MENU.CONTACT_US") }}</a></li>
              </ul>
            </nav>
          </div>
        </div>
        <!--end: Navigation-->
      </div>
    </div>
  </header>


</template>

<script>
export default {
  name: 'Header',
  components: {}
}
</script>
