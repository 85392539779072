<template>
  <section>
    <div class="container">
      <div class="heading-text heading-section text-center">
        <h2>{{ $t("CLIENTS.TITLE").toUpperCase() }}</h2>
        <span class="lead">{{ $t("CLIENTS.SUB_TITLE") }} </span>
      </div>

      <ul class="grid grid-5-columns">
        <li>
          <a href="#"><img src="media/clients/capoterra.png" alt="">
          </a>
        </li>
        <li>
          <a href="#"><img src="media/clients/capoterra.png" alt="">
          </a>
        </li>
        <li>
          <a href="#"><img src="media/clients/capoterra.png" alt="">
          </a>
        </li>
        <li>
          <a href="#"><img src="media/clients/capoterra.png" alt="">
          </a>
        </li>
        <li>
          <a href="#"><img src="media/clients/capoterra.png" alt="">
          </a>
        </li>

      </ul>

    </div>
  </section>
</template>
<script>
export default {
  name: 'Client'
}
</script>
