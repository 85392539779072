import { createI18n } from "vue-i18n";

const messages = {
  en: require('@/core/translations/en.json'),
  it: require('@/core/translations/it.json'),
};

const i18n = createI18n({
  legacy: false,
  locale: "it",
  globalInjection: true,
  messages,
});

export default i18n;
