<template>
  <v-app>
    <div class="body-inner">
      <Header/>
      <Slider/>
      <AboutUs/>
      <Counters v-if="false"/>
      <Services/>
      <div class="seperator p-b-0 p-t-0"><i class="fa fa-chevron-down"></i></div>
      <Clients/>
      <Footer/>
      <a id="scrollTop"><i class="icon-chevron-up"></i><i class="icon-chevron-up"></i></a>
    </div>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import Slider from "@/components/Slider";
import AboutUs from "@/components/AboutUs";
import Counters from "@/components/Counters";
import Services from "@/components/Services";
import Clients from "@/components/Clients";
import Footer from "@/components/Footer";

export default {
  name: 'Base',
  components: {
    Header,
    Slider,
    Counters,
    Services,
    Clients,
    Footer,
    AboutUs
  }
}
</script>
